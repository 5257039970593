.blockdetails .iccc__table--container{
         border: none;
}
.blockdetails .iccc--table th:first-child{
    border-top-left-radius:0px;
}
.blockdetails .iccc--table th:last-child{
    border-top-right-radius:0px;
}

