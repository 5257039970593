.headerstyle {
  padding-left: 0.1rem !important;
  width: 93%;
  background-color: var(--primary-color) !important ;
  border: 1px solid var(--white-color);
  margin-left: 2.5rem;
}

.headerstyle :focus {
  box-shadow: none !important;
}
.accordion-button {
  color: var(--white-color) !important;
  background-color: #252b48 !important ;
  border: none !important;
  outline: none !important;
}
.bodystyle {
  background-color: var(--primary-color) !important ;
}

.accordion-item {
  background-color: var(--primary-color) !important ;
  color: var(--white-color);
}
/* .accordion-button .collapsed {
    padding-left: 0px;
  } */
/* h2 {
    margin-block-start: 0.5rem !important;
  } */
/* .accordion-button .collapsed {
    padding: 0px !important;
  } */

.accordion-body-style {
  padding-bottom: 4.7rem;
  background-color: var(--primary-color) !important ;
}

.accordion-button::after {
    background-color: aliceblue !important;
  }