.add_new_title{
    position: absolute;
    right: 20px;
    bottom: 171px;
    font-size: 12px;
}

.create-user > div > label {
    color: #b6b6b6;
    margin-bottom: 0.6em;
    margin-top: 0.6rem;
  }
  .role.dropdownstyle .select__container {
    padding: 0.4rem 6rem;
    border-radius: 0rem;
  }
  .btn-save {
    width: 100px;
    background-color: rgb(64, 178, 183);
    color: black;
  }
  
  .btn-primary {
    background-color: #40b2b7 !important;
    font-size: 0.7rem !important;
    border: none !important;
    margin-top: 2rem;
    margin-right: 1rem;
  }
  
  .create-user .iccc__table--container {
    padding:0;
    border-radius: 1em;
  }
  .rmsc .dropdown-heading {
    height: 30px !important;
  }
  .dropdown-heading-value span {
    color: #fff;
  }
  .search-form {
    margin: 0 !important;
  }
  
  /* .configuremsg__input {
    background-color: #252b48;
  } */
  
  .form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    box-shadow: none !important;
    background-color: #252b48 !important;
    /* border-color: none !important; */
    color: #ffff !important;
  }
  
  .dropdown-container{
      background-color: #252b48 !important;
  }
  /* 
  ..
  .user__form--selcte{
    width: 10px;
  } */
.hl-table th td{
  border: 1px solid white !important;
}

.hl-usertable__actionsicon{
  align-items: center;
}


.disabled_text {
  opacity: 0.3;
  pointer-events: none;;
  cursor: not-allowed;
}

.disabled_text .react-select__control {
  background-color: #e9ecef;
  cursor: not-allowed;
}