.container {
  display: block;
  position: relative;
  padding-left: 1.7rem;
  cursor: pointer;
  top: 1rem;
  padding-top: 0.5rem;
}
.container label {
  color: white;
   /* color: var(--black-color); */
} 

/* Hide the browser's default checkbox */
.container input[type="checkbox"]{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  background-color:var(--white-color);
  margin-top: 0.66rem;
  border-radius: 0.1rem;
  outline: 2px solid var(--green-color);
  height: 0.9rem;
  width: 0.9rem;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color:var(--green-color);
}

/* Deactivate the outline when checked */
.container input:checked ~ .checkmark {
  outline: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: 1px solid var(--white-color);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.dynamic__checkbox--label {
  color: var(--white-color);
}
