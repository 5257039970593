.details__container {
  overflow-y: auto;
}
.detailshandler__title {
  font-size: 1.2rem;
}
.details__container::-webkit-scrollbar {
  width: 0.1rem;
  border-radius: 100%;
}

.details__container::-webkit-scrollbar-track {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.details__container::-webkit-scrollbar-thumb {
  background-color: var(--grey-color);
  border-radius: 100%;
}

.details__container::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey-color);
}

.whitelist_op--Handler {
  border-radius: 0.5rem;
  width: 5rem;
  text-align: center;
  color: #ffff;
  font-size: 0.9rem;
}

.whitelist__op--background {
  background-color: var(--green8-color);
}
.blacklistlist__op--background {
  background-color: var(--red-color);
}
