/* .flood__reportstatus--table {
  width:100%;
  border-collapse: collapse;
  border: 1px solid #dddddd;
  border-radius: 8px !important;
  margin: 1rem 1rem 1rem 0.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  page-break-inside: auto;
  
}
.flood__reportstatus--table td {
  border: 1px solid #dddddd;
  padding: 5px;
  text-align: left;
  font-size:0.9em;
}
.flood__reportstatus--table th {
  border: 1px solid #dddddd;
  padding: 5px;
  text-align: left;
  font-size:0.9em;
} */

/* .report__status .iccc--table th:first-child{
    border-top-left-radius:0px;
}
.report__status .iccc--table th:last-child{
    border-top-right-radius:0px;
}
.report__status .iccc__table--container {
    border: 1px solid var(--green4text-color);
    padding: 0.5em 1em 0em 1em;
    
    margin: 0 1.3em;
} */


/* .report__status label {
  color: var(--white-font-color);
}
.report__status .table {
  border-collapse: collapse;
}
.report__status th {
  border-color: var(--white-font-color);
  border-right: 1px solid var(--white-font-color);
  border-top: 1px solid var(--white-font-color);
  border-left: 1px solid var(--white-font-color);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
}
.report__status td {
  border-color: var(--white-font-color);
  border-right: 1px solid var(--white-font-color);
  border-top: 1px solid var(--white-font-color);
  border-left: 1px solid var(--white-font-color);
} */


/* .report__status tr{
    border-color:var(--white-font-color); ;
 }
 .report__status td{
    border-color:var(--white-font-color); ;
 } */
/* .report__status .iccc__table--container {
  border: none;
} */

.flood__statusreport--button {
  font-size: 12px;
  background-color: #18a3a0 !important;
  width: 119px;
  margin: 27px 3px !important;
  height: 32px;
}
.completed_count {
  height: 36%;
  width: 80%;
  background-color: lightgreen;
  color: #000;
  border: 1px solid var(--white-font-color);
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.report__status tr{
  border-color:var(--white-font-color); ;
}