/* .modal-content {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
.modal-header {
  border-bottom-width: 0px !important;
  margin-top: 1%;
  margin-left: 3%;
  margin-right: 2%;
}

.modal-body {
  padding-top: 0 !important;
} */

.btn-close {
  color: #fff !important;
}

.form--modal-collapsed {
  margin-top: calc(var(--header-height)) !important;
  margin-left: var(--sidebar-width-collapsed) !important;
}

.form--modal {
  margin-top: calc(var(--header-height)) !important;
  margin-left: var(--sidebar-width) !important;
}

.modal--size-collapsed {
  width: calc(100vw - var(--sidebar-width-collapsed)) !important;
}

.modal--size {
  /* width: calc(100vw - var(--sidebar-width)) !important; */
  min-height: calc(90vh - var(--header-height));
}
