.file__preview--popup .modal-content {
  background-color: var(-white-color) !important;
  height: 26.4rem;
  width: 25rem;
  padding: 0 0.6rem 0 0.6rem;
}

.file__preview--popup .modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  color: var(--black-color);
}

.preview__file--space {
  width: 22rem;
  height: 18.4rem;
  border-radius: 0.4rem;
  margin-top: -12px;
}
.preview_file_space_id {
  width: 21.3rem;
  height: 18rem;
  border-radius: 0.4rem;
  margin-top: -12px;
}

.preview__ok .btn {
  background-color: var(--green-color);
  border: none;
  height: 1.7rem;
  width: 5rem;
  font-size: 0.8rem;
  margin: 0 auto;
  display: block;
  color: var(--white-color);
  align-self: center;
}

.preview__ok {
  margin: 0.8rem 0 0 0;
}

.preview__filename {
  color: var(--black-color);
}

.preview__image {
  width: 100%;
  height: 20rem;
  border: solid 1px var(--grey-color);
}