.dropdown-container {
  border: 1px solid var(--white3-color) !important;
  background-color: var(--secondary-color) !important;
  color: var(--white-font-color) !important;
  font-size: smaller;
  font-weight: 200;
  box-shadow: none !important;
}

.form__multiselect--errorborder {
  border-radius: 0.1rem;
  border: 1px solid  var(--orange-error-color);
  background-color: var(--secondary-color) !important;
  color:var(--white3-color);
  box-shadow: none !important;
}
.react-select {
border: 1px solid var(--white3-color);
  background-color: var(--primary-color)  !important;
  color: var(--white-font-color)  !important;
  font-weight: 200;
  height: 34px;
 box-shadow: none !important;
    }

  .css-13qatl9-multiValue{
    background-color: var(--primary-color) !important;
    padding: 2px;
    border-radius: 11px;
  }
  .css-1mbxqx5-MultiValueRemove:hover{
    background-color:  var(--primary-color) !important;
  }




