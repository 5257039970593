.header-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 30px;
}

.header-container-chart {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-top: 10px;
    margin-right: 30px;
}

.button-Charts {
    margin-left: auto; /* Push the button to the right */
    margin-right: 10px; /* Add spacing from the edge */
}

.toggle-container {
    margin-left: auto; /* Aligns toggle container to the right */
}

.header-container p {
    margin: 0;
    padding: 2px;
}

.p-data{
    margin-left: 2px;
}
.table-container-Bmsk {
    height: 480px; /* Set a fixed height for the container */
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    padding: 3px;
    text-align: center;
}

table th {
    background-color: #1c5393; /* Header background color */
    color: white;
    text-align: center;
    padding: 8px; /* Adjust padding for better alignment */
    position: sticky; /* Make header sticky */
    top: 0; /* Position it at the top of the scrolling container */
    z-index: 1; /* Ensure it stays above other content */
}
.table-container-Bmsk > table > tbody > tr > td {
    white-space: nowrap; /* Prevent text wrapping in outer table */
}

.table-container-Bmsk .expanded-content .inner-table td {
    white-space: normal; /* Allow text wrapping in inner table */
}

.table-container-Bmsk > table > th {
    white-space: nowrap; /* Prevent header text wrapping in outer table */
}

.table-container-Bmsk .expanded-content .inner-table th {
    white-space: normal; /* Allow header text wrapping in inner table */
}



table th:first-child {
    text-align: center; /* Center align the text in the first column */
    white-space: nowrap; /* Ensure "S NO" stays on one line */
}


.dark-blue-row {
    background-color: #252c48; /* Dark blue */
    color: white;
}

.light-blue-row {
    background-color: #393f5f; /* Light blue */
    color: white;
}

.legend-table {
    width: 100%;
    margin-top: 20px;
}

.legend-table td {
    text-align: center;
    padding: 15px;
    font-weight: bold;
    color: white;
}

.legend {
    background-color: #fef5cd; /* Color for Moderate Rain */
}

.moderate-rain-box {
    background-color: #c6ecc6; /* Color for Moderate Rain */
}

.heavy-rain-box {
    background-color: #66b0ff; /* Color for Heavy Rain */
}

.very-heavy-rain-box {
    background-color: #6870e3; /* Color for Very Heavy Rain */
}

.content-container{
    margin-top: 5px;
}

.inner-table{
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative;
    width: 100%; /* Ensures the table takes up the full width of the container */
    border-collapse: collapse; /* Ensures borders don't double up */
    margin: 0 auto; /* Center the table */
}

.inner-table th{
    background-color: #62A8FE;
    border-collapse: collapse; /* Ensures borders don't double up */
    overflow-y: auto; /* Enable vertical scrolling */
    position:static;
}
/* 
.table-container > table > tbody > tr:hover {
    background-color: #3f71b6;
    cursor: pointer;
} */


.block-details-container {
    width: 95%; /* Adjust the width as needed */
    border: 2px solid #62A8FE; /* Adds a thick blue border */
    background-color: #252C48; /* Matches the background color */
    margin: 0 auto; /* Centers the container horizontally */
    display: block; /* Ensures it's treated as a block-level element */
}


