.state-dashboard_div {
  background-color: var(--primary-color);
  height: 100%;
  padding-top: 1rem;
}
.dist__card--container {
  margin: 10px 0px 3px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  background-color: var(--primary-color);
}
.map_view_btn {
  background: #f67d76 !important;
  border: 1px solid #f67d76;
}
.map_view_btn:hover {
  border: 1px solid #f67d76;
}
.dash-title {
  font-weight: 600;
  font-size: 1rem;
}
.toprow--container {
  width: 99.4%;
  margin-left: 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 5px;
  padding-top: 0.8rem;
  padding: 0.6rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker-container {
  display: flex;
  align-items: center;
}

/* .date-picker-container span {
  margin-right: 20px;
} */
.custom-datepicker {
  width: 150px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  height: 1.5rem;
  outline: none;
  background-color: var(--white-color);
}

.dashboard_card-container {
  display: flex;
  gap: 0.6rem;
  padding: 0 0.4rem;
}

.curr-dashboard_card {
  background-color: var(--secondary-color);
  border-radius: 0.3rem;
  margin: 0 0.3rem;
  margin-top: 0.8rem;
}
.curr-dashboard_card-container {
  display: flex;
  padding: 0 0.4rem;
}

.custom-multiselect .searchWrapper {
  width: 100%;
  border-radius: 1;
  /* background-color: none; */
  border: none !important;
  /* color: #000; */
  padding-top: 8pt !important;
}

.custom-multiselect .icon_down_dir {
  background-image: url("../../../../Assets/Icons/caret_down.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
  background-color: #216ba5 !important;

}

.error-message{
  /* margin-top: 40px; */
  color: #f4694c;
  text-align: center;
  margin-top: -20px;
  margin-left: 10px;
}