.sidebar1 {
  width: var(--sidebar-width);
  position: fixed;
  bottom: 10px;
  left: 0;
  background: var(--secondary-color);
  height: 10vh;
  overflow-y: hidden;
  z-index: 999;
}
.sidebarCollapsed {
  width: none;
  position: fixed;
  bottom: 10px;
  left: 0;
  background: var(--secondary-color);
  height: 10vh;
  overflow-y: hidden;
  z-index: 999;
}
.sidebar__content1 {
  display: flex;
  flex-direction: column;
  /* z-index:999; */
  color: var(--white-font-color);
  margin-top: 2em;
  margin-left: 2em;
}
.signout_position {
  padding-left: 0.9rem;
  font-size: 12.6px;
  cursor: pointer;
}

.close-position {
  margin-left: 25rem;
}
.signout_popup .modal-content {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
}
.signout_popup .modal-header {
  border-bottom-width: 0px !important;
  margin-top: 1%;
  margin-left: 3%;
  margin-right: 2%;
  border-bottom: none;
}
