.lms_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 0;
  outline: none;
  font-size: 0.8rem;
}
.btn_vvsm {
  padding: 0.15rem 0.3rem;
  min-width: 1rem;
  height: 1.7rem;
  border-radius: 0.25rem;
  outline: 0.25rem;
}
.btn_vsm {
  padding: 0.15rem 0.3rem;
  min-width: 3rem;
  height: 1.7rem;
  border-radius: 0.25rem;
  outline: 0.25rem;
}

.btn_sm {
  padding: 0.25rem 0.5rem;
  min-width: 5.8rem;
  height: 1.7rem;
  border-radius: 0.25rem;
}

.btn_lg {
  border-radius: 0.4rem;
  padding: 0.5rem 0.8rem;
  min-width: 5.8rem;
  height: 2.3rem;
}

.btn_xlg {
  border-radius: 0.4rem;
  font-size: 1.2rem;
  padding: 0.75rem 1.5rem;
  min-width: 8rem;
}

.lms_btn.primary:hover {
  opacity: 0.8 !important;
  /* Add any other styles you want to apply on hover */
}
