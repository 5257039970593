.timepicker__label {
    color: #ffff; /* Text color for the label */
    margin-bottom: 0.2rem; /* Space below the label */
    font-size: 0.875rem; /* Decreased font size for the label */
  }
 
  .time__container .form__input {
    background-color: var(--secondary-color); /* Background color for input */
    color: var(--white-font-color); /* Text color for input */
    font-size: 0.795rem; /* Decreased font size for input */
  }
 
  .time__container .form-control:focus {
    background-color: var(--secondary-color); /* Background color when focused */
    color: var(--white-font-color); /* Text color when focused */
  }
 
  .time_picker_control {
    background-color: var(--secondary-color); /* Background color for the input field */
    color: var(--white-font-color); /* Text color for input */
    border: 1px solid #ccc; /* Border for input */
    padding: 0.5rem; /* Padding inside the input */
    font-size: 0.795rem; /* Decreased font size for the input */
  }
 
  .error__message {
    color: red; /* Error message text color */
    font-size: 0.75rem; /* Decreased font size for error message */
    margin-top: 0.25rem; /* Space above the error message */
  }

  