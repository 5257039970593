.openStatus {
  background-color: green;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
}
.openText {
  font-size: 0.7rem;
}

.openValue {
  font-size: 0.9rem;
}
.singleCardBlue {
  display: flex;
  width: 100%;
  background-color: #519efe;
  color: var(--white-font-color);
  justify-content: space-between;
  padding: 0.5rem 1.3rem;
  border-radius: 0.2rem;
  margin-top: 1.5rem;
}

.singleCardperpel {
  display: flex;
  width: 100%;
  background-color: #b981f6;
  color: var(--white-font-color);
  justify-content: space-between;
  padding: 0.5rem 1.3rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
}
.singleCardorange {
  display: flex;
  width: 100%;
  background-color: #e28771;
  color: var(--white-font-color);
  justify-content: space-between;
  padding: 0.5rem 1.3rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
}
.singleCardpink {
  display: flex;
  width: 100%;
  background-color: #d252aa;
  color: var(--white-font-color);
  justify-content: space-between;
  padding: 0.5rem 1.3rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
}
