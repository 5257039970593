.flood__report--container {
  margin: 10px;
}
.region_type_container{
  width:20%
}

.region_type_container .category__select {
  height: 1.8rem;
}
.search-container {
  display: flex;
  justify-content: space-around; /* Distributes items evenly */
  gap: 10px; /* Adjust if needed for more space between items */
  padding: 2px; /* Optional: adds padding around the container */
}

.date-picker {
  width: auto; /* Adjust if needed */
}

.overflow-scroll{
  overflow: scroll;
}

.flood__report--table {
  width:100%;
  border-collapse: collapse;
  border: 1px solid #dddddd;
  border-radius: 8px !important;
  margin: 1rem 0rem 0rem 0.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  page-break-inside: auto;
  
}

.flood__report--table tr {
  page-break-inside: avoid;
  page-break-before: auto;
}


b.table__data--heading{
  display: flex;
  justify-content: left;
  font-size:1.1em;
  font-weight: bold;
}


b.table__data--sno_dist_heading{
   display: flex;
  justify-content: center; 
  font-size:1.1em;
  font-weight: bold;
 
}
.flood__report--table td {
  border: 1px solid #dddddd;
  padding: 5px;
  text-align: left;
  font-size:0.9em;
}

.table__data--align {
  text-align: center!important;
}

/* .empty-cell {
  width: 40%;
} */

.flood__report--button {
  font-size: 12px;
  background-color: #18a3a0 !important;
  width: 119px;
  margin: 25px 3px !important;
  height: 31px;
}
.date_picker_control:before{
  content: none !important;
}



@media print {
  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
  }
}


