.file__upload {
    border: 2px dashed var(--grey6-color);
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    cursor: pointer;
    height: 4rem;
    border-radius: 0.4rem;
    vertical-align: "middle";
    margin-left: 0.4rem;
  }
  .upload {
    height: 10rem;
  }
  
  .upload > label {
    color: var(--white-color);
    margin-bottom: 0.2rem;
    font-size: 0.75rem;
    font-family: "Poppins";
    font-style: normal;
  }
  .file__icon--text {
    margin-top: 0.4rem;
  }
  
  .icon__size--file {
    font-size: 2rem;
    text-align: center;
    color: var(--grey-color);
    margin-right: 1.3rem;
    margin-left: 5rem;
  }
  .file-text {
    font-size: 0.9rem;
    color: var(--grey-color);
    margin-top: 1rem;
    margin-left: 1.4rem;
  }
  
  .upload__text {
    font-size: 0.6rem;
    color: #909097;
    /* margin: 0 1.8rem 0 1.8rem; */
  }
  .upload__text--align {
    font-size: 1rem;
    color: var(--black-color);
    margin-top: 1rem;
    margin-right: 1rem;
  }
  
  .file__space {
    max-width: 9.5rem;
    max-height: 8rem;
    text-align: center;
  }
  
  .icon__size {
    font-size: 2rem;
    text-align: center;
    color: var(--grey-color);
    margin-right: 0.4rem;
  }
  
  .file__detail--box {
    width: 14rem;
    height: 3.7rem;
    background-color: var(--primary-color);
    border-radius: 0.2rem;
    padding: 0.5rem;
    max-width: 200px; /* Set your desired maximum width */
    white-space: nowrap;
  }
  
  .file__detail {
    font-size: 0.7rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
    font-family: "Poppins";
    font-style: normal;
    width: 56%;
  }
  
  .reject__iconplace {
    margin-left: 2rem;
    align-items: center;
    margin-top: 0.7rem;
  }
  
  .file_icon {
    font-size: 1.5rem;
  }
  /* 
  .file__name--size {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
  
  .file__name--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  