.form__select {
  border-radius: 0.1rem;
  border: 1px solid var(--white3-color) !important;
  background-color: var(--secondary-color) !important;
  color: var(--white-font-color) !important;
  font-size: smaller;
  font-weight: 200;
  box-shadow: none !important;
  background-image: url(../../../../../Assets/Icons/greendropdownarrow.svg);
}

.form-select:disabled {
  border-radius: 0.1rem;
  border: 1px solid #7c8091 !important;
  background-color: var(--secondary-color) !important;
  color: #7c8091!important;
  font-size: smaller;
  font-weight: 200;
  box-shadow: none !important;
  background-image: url(../../../../../Assets/Icons/greendropdownarrow.svg);
}

.form__label {
  color: var(--white-font-color)!important;
  margin-bottom: 0.2rem;
}

.form__select--errorborder {
  border-radius: 0.1rem;
  border: 1px solid;
  border-color:var(--orange-error-color);
  background-color: var(--secondary-color) !important;
  color: var(--white-font-color) !important;
  font-size: smaller;
  box-shadow: none !important;
}

.form-select:focus {
  background-color: var(--secondary-color);
  border: 2px solid var(--active-color) !important;
  color: var(--white-font-color) !important;
}


.asterisk {
  color: var(--orange-error-color);
  margin-left: 0.3rem;
}