.dashboard_card_fire {
    background-color: var(--secondary-color);
    flex: 1;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0.3rem;
    width: 60%;
    height: 600pt;
  }
  
  .ds-single_card.highlighted {
    border: 3px solid var(--white-font-color);
  }
  
  .dashboard-container {
    display: flex;
    gap: 1rem; /* Space between the sections */
    align-items: flex-start; /* Align items to the top */
  }
  
  .dashboard_cards {
    flex: 1; /* Smaller section */
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Spacing between cards */
    height: "100%";
  }
  
  .weather_data_table {
    flex: 3; /* Wider section */
  }


  
  .card-icon-fire {
    font-size: 6pt; /* Increase size for visibility */
    color: #fff; /* Ensure the icon color is readable */

  }
  .card-label-fire {
    font-size: 0.9rem;
    margin-left: 0px;
    text-align: left;

  }

  .has-sub-data {
    font-size: 0.9rem;
    margin-left: -233px;
    text-align: left;
  }

.nested-sub-data {
  font-size: 0.9rem;
  margin-left: -435px; /* Adjust margin for deeper nesting */
  text-align: left;
}
  .card-data-fire {
    font-size: 0.8rem; /* Increase size for visibility */
    text-align: right;

  }
  .nested-card-data {
    font-size: 0.8rem; /* Increase size for visibility */
    text-align: left;
    margin-left: -36pt;

  }
  .has-card-data {
    font-size: 0.8rem; /* Increase size for visibility */
    text-align: right;

  }
  .ds-single_card_cw_fire{
    display: flex;
    /* justify-content: space-between; */
    padding: 1.4rem 1rem;
    border-radius: 0.2rem;
    color: #fff;
    font-size: 0.7rem;
  }
  .grid-container-cw {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
  }
  
  .ds-single_card_cw {
    transition: all 0.3s ease;
  }
   
/* ******** */
  

  
  /* .ds-single_card_cw {
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    color: white;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
  } */
  
  .grid-container-cw-fire {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 15px;
    width: 490px;
    overflow: hidden;
  }
  
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .highlighted-card {
    border: 2px white;
  }
  /* .sub-data-item.highlighted-card {
    border: 3px solid #ff5733;
  } */
  
  .d-grid {
    display: grid;
  }
  
  .gap-2 {
    gap: 0.5rem;
  }
  
  .mt-1 {
    margin-top: 0.25rem;
  }

  .card-container {
    display: flex;
    flex-direction: column;
  }
  
  .expand-icon {
    margin-left: auto;
    cursor: pointer;
  }
  
  .sub-data-container {
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: 10px;
    gap: 10px;
    justify-content: center;
  }
  
  .sub-data-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    min-width: 80px;
  }
  
  .sub-label {
    font-size: 12px;
    color: #fff;
    margin-top: 5px;
  }
  
  .sub-value {
    font-size: 14px;
    font-weight: bold;
    color: #ffeb3b;
  }
  