.select__container {
  font-size: 0.7rem;
  color: var(--primary-color);
  border-style: hidden;
  background-color: var(--white-color)!important;
  cursor: pointer;
  padding: 0.1rem 1rem;
  border-radius: 0.2rem;
  text-transform: capitalize;
}

.select__container:focus,
:active {
  outline: none;
}

.option-text {
  text-transform: capitalize;
}
