.dashboard_card_Coldwave {
    background-color: var(--secondary-color);
    flex: 1;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0.3rem;
    width: 60%;
    height: 514pt;
  }
  
  .ds-single_card.highlighted {
    border: 3px solid var(--white-font-color);
  }
  
  
  .dashboard_card_Coldwave .gap-2 {
    gap: 1.6rem !important;
}
  .dashboard-container {
    display: flex;
    gap: 1rem; /* Space between the sections */
    align-items: flex-start; /* Align items to the top */
  }
  
  .dashboard_cards {
    flex: 1; /* Smaller section */
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Spacing between cards */
    height: "100%";
  }
  
  .weather_data_table {
    flex: 3; /* Wider section */
  }


  
  .card-icon_coldwave {
    font-size: 6pt; /* Increase size for visibility */
    color: #fff; /* Ensure the icon color is readable */

  }
  .card-label-coldwave {
    font-size: 0.8rem;
    margin-left: 0px;
    text-align: left;

  }
  .card-data-coldwave {
    font-size: 0.8rem; /* Increase size for visibility */
    text-align: right;

  }
  .ds-single_card_cw {
    display: flex;
    /* justify-content: space-between; */
    padding: 1.4rem 1rem;
    border-radius: 0.2rem;
    color: #fff;
    font-size: 0.7rem;
  }
  .grid-container-cw {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
  }
  
  .ds-single_card_cw {
    transition: all 0.3s ease;
  }
   
/* ******** */
  
  /* .dashboard_card_Coldwave {
    padding: 10px;
    background-color: #252b48;
    border-radius: 8px;
  } */
  
  /* .ds-single_card_cw {
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    color: white;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
  } */
  
  .grid-container-cw {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 15px;
  }
  
  .card-icon_coldwave {
    display: flex;
    align-items: center;
  }
  
  .card-label-coldwave {
    font-size: 14px;
    font-weight: 500;
  }
  
  .card-data-coldwave {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .highlighted-card {
    border: 2px solid #ffffff !important;
  }
  
  .d-grid {
    display: grid;
  }
  
  .gap-2 {
    gap: 0.5rem;
  }
  
  .mt-1 {
    margin-top: 0.25rem;
  }