.radio__item {
  display: inline-block;
  position: relative;
  padding: 0 0.375rem;
  margin: 0.625rem 0 0;
}

.radio__item input[type="radio"] {
  display: none;
}

.radio__item label {
  color: white;
  font-weight: normal;
    }

.radio__item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 0.313rem;
  margin: 0 0.313rem 0 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.688rem;
  background-color: #d9d9d9;
}

.radio__item input[type="radio"]:checked + label:after {
  border-radius: 0.688rem;
  width: 0.75rem;
  height: 0.75rem;
  position: absolute;
  top: 0.563rem;
  left: 0.625rem;
  content: " ";
  display: block;
  background: #40b2b7;
}


