.acknowldge_icon{
  color: var(--active-color) !important;
  font-size: 12pt;
}
.action_container{
  display: block;
  width: 100%;
  text-align: center;
}
.accordion {
  background-color: #252B48!important;
}
.accordion-button{
  padding: 1% !important;
  font-size: 12px!important;
  background-color: var(--secondary-color)!important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0!important;
  box-shadow: 0 0 0 0.5px #fff;
}