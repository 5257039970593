.multi_file_upload {
  width: 10rem;
  border: 2px dashed var(--grey6-color);
  border-radius: 0.4rem;
  padding: 0.1rem 0.5rem 0.5rem 0.5rem;
}
.multifile-all_card_view {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.multifile__detail--box {
  width: 8rem;
  height: 6rem;
  background-color: #000;
  border-radius: 0.3rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.reject__icon {
  position: absolute;
  left: 83%;
  top: 6%;
}
.icon__size--multifile {
  font-size: 2rem;
  text-align: center;
  color: var(--grey-color);
}
.multifile-image {
  width: 100%;
  height: 6rem;
  border-radius: 0.3rem;
}
.multi-file_text {
  padding-top: 0.3rem;
  font-size: 0.8rem;
  text-align: center;
}
.summary-icon--multifile {
  padding-top: 1rem;
  font-size: 2rem;
  text-align: center;
  color: var(--grey-color);
}
