.map__container {
  width: 100%;
  height: 100%;
  padding: 1%;
}

.map__container--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12%;
  padding: 0 1% 1% 1%;
}

.map__container--export {
  display: flex;
}

.map__container--export--text {
  padding-right: 0.3em;
}

.map__container--export--icon {
  margin-right: 0.5em;
}

.map__container--body {
  height: 87%;
  /* height: auto; */
}

.map__header--text {
  font-size: 1.1em;
}
