.form9flood__table-container{
  margin:1em;
  width:60%;
}
  .form9flood__report--table{
    border-collapse: collapse;
    border:1px solid #dddddd;
    border-radius:8px;
    font-family: "Poppins";
    font-style:normal;
    font-weight:300;
    margin:2em 2em 2em 2em;
    table-layout:auto;
  }

  .form9flood__report--table td {
    border:1px solid #dddddd;
    text-align:center;
    font-size:0.9em;
  }

  b.table__data--headings{
    display:flex;
    justify-content:center;
    font-size:1em;
    font-weight: bold;
    font-style: normal;
  }


  .form9__report--button {
    font-size:12px;
    background-color:var( --green3-color) !important;
    width:15%;
    text-align: center;
    margin:1em 1em 1em 2.3em!important;
    height:32px;
  }


  



