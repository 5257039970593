.reset-button {
  background-color: transparent;
  color: var(--white-color);
  border: none;
  padding: 0;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 1em;
}

.reset-button:hover {
  opacity: 0.6;
}
