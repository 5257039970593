
.custom-multiselect .searchWrapper {
    width: 100%;
    border-radius: 1;
    /* background-color: none; */
    border: none !important;
    /* color: #000; */
    padding-top: 8pt !important;
  }
  .ckmodal-container-coldwave{
    margin-top: -4rem;
  }
  
  .table-cell-left {
    text-align: left;
    padding-left: 10pt;
  }
  
  .table-cell-center {
    text-align: center;
  }

.card-title_new_cw{
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}

.error-message-coldwave{
  /* margin-top: 40px; */
  color: #f4694c;
  text-align: center;
  margin-top: -20px;
  margin-left: 87px;
}