.iframecontainer{
    width: 100%;
}
iframe{      
    display: block;  /* iframes are inline by default */   
    height: 100vh;  /* Set height to 100% of the viewport height */   
    width: 100%;  /* Set width to 100% of the viewport width */     
    border: none; /* Remove default border */
    
}
.nonfullscreen{
    height: 100%;
}
.maximizebutton{
    position: absolute;
    right: 1px;
    bottom: 0px;
    z-index: 9;
    border: none;
    color: #161515;
    padding: 0px;
    /* background: #fff; */
    font-size: 26px;
    color: var(--active-color);
    background: none;
}
.mapcontrols{
    position: absolute;
    right:1px;
    /* top:48px; */
    z-index: 9;
}
.mapcontrolicon{
    color: #d8d5d5;
    font-size: 30px;
    /* background: #fff; */
    cursor: pointer;
    /* background: #570808; */
}
.mapcontrolicon:hover{
    color: #570808;
}
.maxicon:hover{
    background-color: #d8d5d5;
}
.maxicon{
    background: #570808;
}
.headerRegionContent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}

.ol-popup {
    position: absolute;
    /* background-color: white; */
    background-color:var(--secondary-color) !important;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
    color: #fff;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "✖";
    color: #fff;
  }

  @keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blinking-text {
    /* text-align: center; */
    /* margin-top: 20%; */
    /* font-size: 24px; */
    color: #fff;
    animation: blink 1s infinite; 
}