/* Single Select Container */
.single-select__control {
    border: 1px solid var(--white3-color) !important;
    background-color: var(--secondary-color) !important;
    color: var(--white-font-color) !important;
    font-size: smaller;
    font-weight: 200;
    box-shadow: none !important;
    height: 30px;
  }
  
  /* Single Select Dropdown Menu */
  .single-select__menu {
    background-color: var(--secondary-color) !important;
    color: var(--white-font-color) !important;
  }
  
  /* Single Select Option */
  .single-select__option {
    background-color: var(--secondary-color) !important;
    color: var(--white-font-color) !important;
    font-size: 12px;
  }
  
  .single-select__option--is-focused {
    background-color: #2684ff !important;
    color: var(--white-font-color) !important;
  }
  
  .single-select__option--is-selected {
    background-color: #2684ff !important;
    color: var(--white-font-color) !important;
  }
  
  /* Single Select Placeholder */
  .single-select__placeholder {
    color: var(--white3-color) !important;
  }
  
  /* Single Select Dropdown Indicator */
  .single-select__dropdown-indicator {
    color: var(--white3-color) !important;
  }
  
  /* Single Select Clear Indicator */
  .single-select__clear-indicator {
    color: var(--white3-color) !important;
  }
  
  /* Single Select MultiValue (for tags, if applicable) */
  .single-select__multi-value {
    background-color: var(--primary-color) !important;
    padding: 2px;
    border-radius: 11px;
  }
  
  .single-select__multi-value__label {
    color: var(--white-font-color) !important;
  }
  
  .single-select__multi-value__remove:hover {
    background-color: var(--primary-color) !important;
  }
  
  /* Error State */
  .single-select__control--is-invalid {
    border-color: var(--orange-error-color) !important;
  }

  .css-166bipr-Input {
    color: #ffffff !important;
}

.css-1cfo1cf {
    color: #ffffff !important;
}

/* Single Select Selected Value */
.single-select__single-value {
    color: var(--white-font-color) !important; /* Set text color to white */
  }