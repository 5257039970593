.primary-tbl th{
    background: #18a3a0;
    color: #FFFFFF;
    border-top: 1px solid  #f0f0f0;
    border-bottom: 1px solid  #f0f0f0;
    /* border-radius: 12px; */
}
.primary-tbl td{
    /* background: #18a3a0; */
    color: #FFFFFF;
    border-top: 1px solid  #f0f0f0;
    border-bottom: 1px solid  #f0f0f0;
    /* border-radius: 12px; */
    padding: 10px;
}
.primary-tbl th:first-child{
    background: #18a3a0;
    color: #FFFFFF;
    /* border-top-left-radius: 12px;
    border-bottom-left-radius: 12px; */
}
.detailed-tbl th{
    background-color: #1c5393;
    color: white;
    text-align: center;
    padding: 8px;
    position: sticky;
    top: 0;
    z-index: 1;
}
.detailed-tbl th:first-child{
    background: #1c5393;
    color: #FFFFFF;
    /* .. border-top-left-radius: 12px;
    border-bottom-left-radius: 12px; */
}


.iccc__table--container{
    padding: 0pt !important;
}
.flood__report--button-hl{
    background: none !important;
    border: 1px solid white;
}

.hl-select-ddwn {
    border: 1px solid white !important;
    background: none !important;
}
select option {
    margin: 40px;
    background: #252b48;
    color: #fff;
    /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
  }


.disabled_icon {
    opacity: 0.3;
    pointer-events: none;
}
