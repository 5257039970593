.resourceregform__regionmap--position {
    padding-left: 42rem;
    font-size: 0.9rem;
  }
  
  .resourceregform__sharelabel--position {
    padding-left: 1rem;
    font-size: 0.8rem;
  }
  
  .radio-style {
    color: white;
    padding-left: 1rem;
  }
  .tag-item .search {
    width:100%;

    border:none;
    background-color: transparent;;
  }
  .react-dropdown-tree-select .dropdown{
    width:100%;
  }
  .tag-item {
    margin-right:3px
}
 
  .dropdown-trigger{
    width:100%;
  }
  .dropdown-content{
    width: 100%;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #fff !important;
    color: #fff;
  }
  .node-label{
    color: #fff;
  }
  .node {
    background-color:#189A99;
  }
  .node.focused{
    background-color: rgb(24, 163, 160);
  }
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after{
    color: #fff;
    position: absolute;
    right: 3px;
    top: 7px;
  }
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    color: #fff;
    position: absolute;
    content: url(../../../../Assets/Icons/greendropdownarrow.svg);
    right: 9px;
    top: 9px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 14px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  border: 1px solid #b9b9b9;
}