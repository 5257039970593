.import__popup .modal-content {
  background-color: white !important;
  height: 19rem;
  width: 50rem;
}

.import__popup .modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #a0a0a0;
}
