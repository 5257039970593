.dashboard_card {
  background-color: var(--secondary-color);
  flex: 1;
  padding: 1rem 1rem;
  border-radius: 0.3rem;
  /* width: 60%; */
  /* height: 815px; */
}

.ds-single_card {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 0.7rem;
}
.ds-single_card.highlighted {
  border: 3px solid var(--white-font-color);
}
/* .card-label-humanloss {
  font-size: 0.8rem;
  margin-left: -36px;
} */
/*  */

.dashboard-container {
  display: flex;
  gap: 1rem; /* Space between the sections */
  align-items: flex-start; /* Align items to the top */
}

.dashboard_cards {
  flex: 1; /* Smaller section */
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Spacing between cards */
  height: "100%";
}

.weather_data_table {
  flex: 3; /* Wider section */
}

.d-grid {
  max-height: 750px; /* Adjust this value based on the desired height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.card-icon-humanloss {
  font-size: 6pt; /* Increase size for visibility */
  color: #fff; /* Ensure the icon color is readable */
}

.card-label-humanloss {
  font-size: 0.8rem;
  margin-left: 0px;
  text-align: left;
}
.card-data-humanloss {
  font-size: 0.8rem; /* Increase size for visibility */
  text-align: right;
}
.ds-single_card_cw {
  display: flex;
  /* justify-content: space-between; */
  padding: 1.4rem 1rem;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 0.7rem;
}
.grid-container-cw {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}

/* Add this to HumanDashboardCard.css */

.ds-single_card_cw {
  transition: all 0.3s ease;
}

.highlighted-card {
  border: 3px solid white; /* Apply white border when card is clicked */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Optional: Add shadow for better effect */
}


