.tab-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.tab {
  color: #fff;
  font-weight: 500;
  display: flex;
  padding: 0 1rem;
  min-width: 8rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.tab[data-isEnabled="true"] {
  cursor: pointer;
}

.tab-active {
  color: #17a3a0;
  border-bottom: 5px solid #17a3a0;
  font-weight: 600;
  font-size: 1.1rem;
}
