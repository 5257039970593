.filter-btn-group .btn-inactive {
  background-color: #f3f5f7;
  color: #909097;
}

.filter-btn-group .btn-active {
  background-color: #18a3a0;
  color: #fff;
}

.filter-btn-group .btn {
  font-size: 0.8rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  min-width: 5rem;
}


.filter-btn-group .btn:hover {
  background-color: #18a3a040 !important;
}
