.affected-table {
  /* border-collapse: collapse; */
  width: 100%;
  /* margin: 0.4rem; */
}

.affected-table th,
.affected-table td {
  padding: 9px;
  text-align: center;
}

.affected-table th {
  color: var(--active-color);
  border-bottom: 1px solid var(--white-color);
}
.affected-table tr {
  border-bottom: 1px solid var(--white-color); /* Thin grey horizontal line below each row */
}

.affectedtable-header-style {
  background-color: var(--green-color);
  padding:1% 0% 1% 2%;
  border-radius: 13px 13px 0 0;
}
.affectedtable-container {
  height: 100%;
}

.affected-population-labelstyle{
  color: var(--active-color);
}
.mainsection{
  height: 100%
}
.section1{
  height:40% ;
}
.section1{
  height:400% ;
}

.form__input{
  height: 29px;
}
.applybutton-style{
  background-color: var(--green-color);
  height: 30px;
  width:60px;
  color: var(--white-color);
  align-items: center;
  margin-top: 1.3rem;
 margin-left: 0.8rem;
}