.file__upload--import {
  border: 1.5px dashed #979797;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  cursor: pointer;
  height: 4.8rem;
  width: 28rem;
  border-radius: 0.4rem;
  vertical-align: "middle";
  margin-left: 0.4rem;
  margin-top: 0.3rem;
}
.icon__size--import {
  font-size: 2rem;
  text-align: center;
  color: #979797;
  margin-right: 0.4rem;
}

.import-text {
  font-size: 1rem;
  color: black;
  margin-top: 1rem;
  margin-right: 1rem;
}
.import-upload {
  height: 4rem;
  width: 2rem;
}

.import__icon--text {
  margin-top: 0.6rem;
}
.import-select .btn-primary {
  color: white;
  font-size: 0.8rem;
  font-weight: 100;
  width: 6rem;
  padding-left: 1rem;
  background-color: #17a3a0;
  margin-top: 1rem;
  /* margin-left: 13rem; */
}

.importfile__detail--box {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 17rem;
  height: 3rem;
  border-radius: 0.2rem;
  padding: 0.5rem;
  padding: 0.2rem 0rem 0.2rem 0.2rem;
  margin-left: 1rem;
  margin-top: 0.8rem;
}

.importfile__name--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #17a3a0;
}
.importfile-size {
  color: black;
}

.import-submit .btn-primary {
  color: white;
  font-size: 0.8rem;
  font-weight: 100;
  width: 6rem;
  padding-left: 1rem;
  background-color: #17a3a0;
  margin-top: 0.5rem;
  margin-left: 3.2rem;
}

.import__reject--iconplace {
  margin-left: 2.7rem;

  margin-top: 0.7rem;
}

.import__file--heading {
  color: black;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.template_box {
  padding: 0.7rem 0.8rem 0 0.8rem;
}

.or_text {
  color: #ffa100;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}
.download__template--text {
  padding: 2rem 0 0 1rem;
  color: black;
}

.import-download {
  padding-left: 5rem;
}

.import-download .btn-primary {
  color: white;
  font-size: 0.8rem;
  font-weight: 100;
  width: 6rem;
  padding-left: 1rem;
  background-color: #17a3a0;
  margin-top: 1.3rem;
  margin-left: 0.8rem;
}

.import-template {
  margin-top: 3rem;
}
