.modal-dialog.modal-right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30%;
  transform: translateX(100%) !important;
  transition: transform 0.3s ease-in-out !important;
}

.modal.show .modal-dialog.modal-right {
  transform: translateX(0) !important;
}

.column__filter--title {
  font-size: 1.2rem;
  color: var(--color-G) !important;
}

.modal-content {
  height: 100%;
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.modal-header {
  background-color: #252b48; /* Same solid blue for consistency */
}
.modal-header .lms__title {
  font-size: 1.5rem;
}
/* .modal-body { */
  /* background-color: var(--white-color); */
  /* background-color: #cfcfcf; */
/* } */
.filter__container input:checked ~ .checkbox__checked {
  background-color: var(--green-color) !important;
  outline: none;
}

.checkbox__checked:after {
  content: "";
  position: absolute;
  display: none;
}

.filter__container input:checked ~ .checkbox__checked:after {
  display: block;
}

.filter__container .checkbox__checked:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid var(--white-color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filter__container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox__checked {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  margin-top: 0.8rem;
  border-radius: 0.1rem;
  outline: none;
  height: 0.9rem;
  width: 0.9rem;
  cursor: pointer;
  border: 1px solid var(--green-color);
}

.filter__container {
  display: block;
  position: relative;
  padding-left: 1.7rem;
  cursor: pointer;
  font-size: 22px;
}

.checkbox-list {
  padding-left: 1.9rem;
  height: 50%;
}
.filter__modal--body {
  height: 85vh;
  overflow: hidden;
  overflow-y: auto;
}

.filter__container label {
  font-size: 14px;
  color: var(--white-font-color);
}
.modal__header--ColumnFilter .modal-content {
  background-color: #252b48; /* Replace #0000FF with any shade of blue you want */
  color: var(--logoutcontent-color);
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.ColumnFilter__modal--body {
  background-color: #252b48; /* Solid blue background */
  margin: 0;
}

.modal__header--ColumnFilter .modal-title {
  color: var(--modalheadertitle-activebg);
}

.column-filter-search {
  width: 100%;
  outline: none;
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
}
