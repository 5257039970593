.image_container--maindiv {
  width: 100%;
  height: 100%;
  position: absolute;
}
.profile_image {
  position: relative;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 15rem;
  height: 15rem;
  border: 0.3rem solid #fff;
  border-radius: 10rem;
}

.profile_image--image {
  border-radius: 10rem;
}
#contrast .profile_image {
  border: 0.3rem solid #951b1d;
}

.user-profile_detail {
  line-height: 2rem;
  text-align: center;
  margin-top: 1rem;
}

.edit-profile_icon {
  margin-top: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
